import { Button, Form, Modal, Radio, notification } from "antd";

import { POST } from "../../../../providers/useAxiosQuery";
import FloatTextArea from "../../../../providers/FloatTextArea";
import notificationErrors from "../../../../providers/notificationErrors";
import validateRules from "../../../../providers/validateRules";

export default function ModalUploadFileStatus(props) {
	const {
		toggleModalFormUploadFileStatus,
		setToggleModalFormUploadFileStatus,
		selectedRowKeys,
		setSelectedRowKeys,
		setDataSource,
	} = props;

	const [form] = Form.useForm();

	const { mutate: mutateGradeFileStatus, isLoading: isLoadingGradeFileStatus } =
		POST(`api/grade_file_status`, [
			"grade_file_list",
			"faculty_load_profile_status_list",
		]);

	const onFinish = (values) => {
		let data = {
			status: values.status,
			remarks: values.remarks,
			ids: selectedRowKeys.gradeFileIds,
		};

		mutateGradeFileStatus(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Grade File",
						description: res.message,
					});

					setToggleModalFormUploadFileStatus(false);

					setDataSource((prev) => {
						return prev.filter(
							(item) => !selectedRowKeys.facultyIds.includes(item.id)
						);
					});

					form.resetFields();

					setSelectedRowKeys({
						facultyIds: [],
						gradeFileIds: [],
					});
				} else {
					notification.error({
						message: "Grade File",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	return (
		<Modal
			title="Grade File Status"
			open={toggleModalFormUploadFileStatus}
			onCancel={() => {
				setToggleModalFormUploadFileStatus(false);
			}}
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={1}
					onClick={() => {
						setToggleModalFormUploadFileStatus(false);
						form.resetFields();
					}}
					disabled={isLoadingGradeFileStatus}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					type="primary"
					key={2}
					onClick={() => form.submit()}
					loading={isLoadingGradeFileStatus}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Form.Item
					name="status"
					rules={[validateRules.required()]}
					label="Status"
				>
					<Radio.Group>
						<Radio value="Incomplete">Incomplete</Radio>
						<Radio value="Approved">Approved</Radio>
					</Radio.Group>
				</Form.Item>

				<Form.Item name="remarks">
					<FloatTextArea label="Remarks" placeholder="Remarks" required />
				</Form.Item>
			</Form>
		</Modal>
	);
}
