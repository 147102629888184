import { useEffect, useState } from "react";
import { Button, Col, Modal, notification, Row, Table } from "antd";

import ModalUploadFile from "./ModalUploadFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-regular-svg-icons";

import { apiUrl } from "../../../../providers/companyInfo";
import { useTableScrollOnTop } from "../../../../providers/CustomTableFilter";
import ModalUploadFileStatusPreview from "./ModalUploadFileStatusPreview";
import ModalUploadFileStatus from "./ModalUploadFileStatus";

export default function ModalFacultyLoad(props) {
	const { toggleModalFacultyLoad, setToggleModalFacultyLoad, from } = props;

	const [selectedRowKeys, setSelectedRowKeys] = useState({
		facultyIds: [],
		gradeFileIds: [],
	});

	const [toggleModalFormUploadFile, setToggleModalFormUploadFile] = useState({
		open: false,
		data: null,
	});
	const [toggleModalFormUploadFileShow, setToggleModalFormUploadFileShow] =
		useState({
			open: false,
			data: null,
		});
	const [toggleModalFormUploadFileStatus, setToggleModalFormUploadFileStatus] =
		useState(false);

	const [dataSource, setDataSource] = useState([]);

	useEffect(() => {
		if (toggleModalFacultyLoad.data) {
			setDataSource(toggleModalFacultyLoad.data);
		}

		return () => {};
	}, [toggleModalFacultyLoad]);

	useTableScrollOnTop("tblGradeFileApprovalList", from);

	return (
		<>
			<Modal
				title={`${toggleModalFacultyLoad.fullname}'s Subjects`}
				open={toggleModalFacultyLoad.open}
				wrapClassName="modal-wrap-faculty-load"
				onCancel={() => {
					setToggleModalFacultyLoad({
						open: false,
						data: null,
						fullname: null,
					});
				}}
				footer={[
					<Button
						className="btn-main-primary outlined"
						key={1}
						onClick={() => {
							setToggleModalFacultyLoad({
								open: false,
								data: null,
								fullname: null,
							});
						}}
					>
						CLOSE
					</Button>,
				]}
			>
				<Row gutter={[20, 20]}>
					{selectedRowKeys.facultyIds.length > 0 ? (
						<Col xs={24} sm={24} md={24}>
							<Button
								className="btn-main-primary"
								onClick={() => setToggleModalFormUploadFileStatus(true)}
							>
								Update Status
							</Button>
						</Col>
					) : (
						<div />
					)}

					<Col xs={24} sm={24} md={24}>
						<Table
							id="tblGradeFileApprovalList"
							className="ant-table-default ant-table-striped"
							dataSource={dataSource}
							rowKey={(record) => record.id}
							pagination={false}
							bordered={false}
							scroll={{ x: "max-content" }}
							sticky
							{...(from === "grade_file_status_approval" && {
								rowSelection: {
									selectedRowKeys: selectedRowKeys.facultyIds,
									onSelect: (record, selected, selectedRows) => {
										let selectedRowKeysCopy = [...selectedRowKeys.facultyIds];
										let selectedRowKeysGradeFileCopy = [
											...selectedRowKeys.gradeFileIds,
										];

										if (selected) {
											if (record.gradeFileStatus === "Uploaded") {
												selectedRowKeysCopy.push(record.id);
												selectedRowKeysGradeFileCopy.push(
													record.grade_files[0].id
												);
											} else if (record.gradeFileStatus === "Approved") {
												notification.error({
													message: "Faculty Load",
													description: "This subject is already approved.",
												});
											} else {
												notification.error({
													message: "Faculty Load",
													description: "No grade file uploaded.",
												});
											}
										} else {
											if (record.gradeFileStatus === "Uploaded") {
												selectedRowKeysCopy = selectedRowKeysCopy.filter(
													(f) => f !== parseInt(record.id)
												);
												selectedRowKeysGradeFileCopy =
													selectedRowKeysGradeFileCopy.filter(
														(f) => f !== parseInt(record.grade_files[0].id)
													);
											}
										}
										setSelectedRowKeys({
											facultyIds: selectedRowKeysCopy,
											gradeFileIds: selectedRowKeysGradeFileCopy,
										});
									},
									onSelectAll: (selected, selectedRows, changeRows) => {
										let selectedRowsFiltered = selectedRows
											.filter((item) => item.gradeFileStatus === "Uploaded")
											.map((item) => item.id);
										if (selected) {
											if (
												selectedRowKeys.length !== selectedRowsFiltered.length
											) {
												setSelectedRowKeys({
													facultyIds: selectedRowsFiltered,
													gradeFileIds: selectedRowsFiltered.map(
														(item) => item.grade_files[0].id
													),
												});
											} else {
												setSelectedRowKeys({
													facultyIds: [],
													gradeFileIds: [],
												});
											}
										} else {
											setSelectedRowKeys({
												facultyIds: [],
												gradeFileIds: [],
											});
										}
									},
								},
							})}
						>
							<Table.Column
								title="Status"
								key="gradeFileStatus"
								dataIndex="gradeFileStatus"
								align="center"
								width={100}
								sorter
							/>

							<Table.Column
								title="Section"
								key="section"
								dataIndex="section"
								width={120}
								sorter
							/>

							<Table.Column
								title="Subject"
								key="code"
								dataIndex="code"
								width={130}
								sorter
							/>
							<Table.Column
								title="Room"
								key="room_code"
								dataIndex="room_code"
								width={120}
								sorter
							/>
							<Table.Column
								title="School Year"
								key="school_year"
								dataIndex="school_year"
								width={150}
								sorter
							/>
							<Table.Column
								title="Semester"
								key="semester"
								dataIndex="semester"
								width={150}
								sorter
							/>
							{from === "grade_file_status" && (
								<Table.Column
									title="Notes"
									key="description"
									dataIndex="description"
									sorter
									width={200}
								/>
							)}
							<Table.Column
								title="Preview Attachment"
								key="preview_attachment"
								align="center"
								width={120}
								render={(_, record) => {
									console.log("Preview Attachment record: ", record);

									return (
										<Button
											type="link"
											className="color-1"
											onClick={() => {
												console.log("record: ", record);

												if (
													record.grade_files &&
													record.grade_files.length > 0
												) {
													let grade_files = record.grade_files;
													console.log("grade_files: ", grade_files);

													let profile = record.profile;
													let attachmentsSig = profile.attachments.filter(
														(f) => f.file_description === "Signature"
													);
													let pdf_file = null;
													let grade_file = {};

													if (grade_files.length > 0) {
														grade_file = grade_files[0];
														let attachments = grade_files[0].attachments.filter(
															(f) => f.file_description === "Grade File"
														);

														if (attachments.length > 0) {
															pdf_file = attachments[0].pdf_file;
														}
													}

													let signature = null;

													if (attachmentsSig.length > 0) {
														signature = apiUrl(attachmentsSig[0].file_path);
													}

													setToggleModalFormUploadFileShow({
														open: true,
														data: { ...grade_file, pdf_file, signature },
													});
												} else {
													let profile = record.faculty_load.profile;
													let attachmentsSig = profile.attachments.filter(
														(f) => f.file_description === "Signature"
													);
													let pdf_file = null;
													let grade_file = record;

													let attachments = record.attachments.filter(
														(f) => f.file_description === "Grade File"
													);

													if (attachments.length > 0) {
														pdf_file = attachments[0].pdf_file;
													}

													let signature = null;

													if (attachmentsSig.length > 0) {
														signature = apiUrl(attachmentsSig[0].file_path);
													}

													setToggleModalFormUploadFileShow({
														open: true,
														data: { ...grade_file, pdf_file, signature },
													});
												}
											}}
										>
											<FontAwesomeIcon icon={faEye} />
										</Button>
									);
								}}
							/>
						</Table>
					</Col>
				</Row>
			</Modal>

			<ModalUploadFile
				toggleModalFormUploadFile={toggleModalFormUploadFile}
				setToggleModalFormUploadFile={setToggleModalFormUploadFile}
			/>
			<ModalUploadFileStatusPreview
				toggleModalFormUploadFileShow={toggleModalFormUploadFileShow}
				setToggleModalFormUploadFileShow={setToggleModalFormUploadFileShow}
			/>
			<ModalUploadFileStatus
				toggleModalFormUploadFileStatus={toggleModalFormUploadFileStatus}
				setToggleModalFormUploadFileStatus={setToggleModalFormUploadFileStatus}
				selectedRowKeys={selectedRowKeys}
				setSelectedRowKeys={setSelectedRowKeys}
				setDataSource={setDataSource}
			/>
		</>
	);
}
