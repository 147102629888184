import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/pro-regular-svg-icons";
import {
	Button,
	Checkbox,
	Form,
	Modal,
	Radio,
	Upload,
	notification,
} from "antd";

import { POST } from "../../../../providers/useAxiosQuery";
import validateRules from "../../../../providers/validateRules";
import FloatTextArea from "../../../../providers/FloatTextArea";
import notificationErrors from "../../../../providers/notificationErrors";

export default function ModalUploadFile(props) {
	const { toggleModalFormUploadFile, setToggleModalFormUploadFile } = props;

	const [form] = Form.useForm();

	const { mutate: mutateGradeFile, isLoading: isLoadingGradeFile } = POST(
		`api/grade_file`,
		"faculty_load_list"
	);

	const onFinish = (values) => {
		console.log("onFinish values", values);
		let data = new FormData();
		data.append("file", values.file[0].originFileObj);
		data.append("type", values.type);
		data.append("description", values.description ?? "");
		data.append("signature_status", values.signature_status ? 1 : 0);
		data.append(
			"faculty_load_id",
			toggleModalFormUploadFile.data && toggleModalFormUploadFile.data.id
				? toggleModalFormUploadFile.data.id
				: ""
		);

		mutateGradeFile(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Grade File",
						description: res.message,
					});

					setToggleModalFormUploadFile({ open: false, data: null });

					form.resetFields();
				} else {
					notification.error({
						message: "Grade File",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	useEffect(() => {
		if (toggleModalFormUploadFile.open) {
			form.resetFields();
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalFormUploadFile]);

	return (
		<Modal
			title="Grade File"
			open={toggleModalFormUploadFile.open}
			wrapClassName="modal-wrap-grade-file"
			onCancel={() => {
				setToggleModalFormUploadFile({
					open: false,
					data: null,
				});
			}}
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={1}
					onClick={() => {
						setToggleModalFormUploadFile({
							open: false,
							data: null,
						});
						form.resetFields();
					}}
					disabled={isLoadingGradeFile}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					type="primary"
					key={2}
					onClick={() => form.submit()}
					loading={isLoadingGradeFile}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Form.Item
					name="file"
					valuePropName="fileList"
					getValueFromEvent={(e) => {
						if (Array.isArray(e)) {
							return e;
						}

						return e?.fileList;
					}}
					rules={[validateRules.required()]}
				>
					<Upload.Dragger
						className="upload-w-100 upload-hide-remove-icon"
						accept="application/pdf"
						multiple
						beforeUpload={(file) => {
							let error = false;
							const isLt2M = file.size / 102400 / 102400 < 5;
							if (!isLt2M) {
								notification.error({
									message: "Faculty Monitoring Justification",
									description: "PDF must smaller than 5MB!",
								});
								error = Upload.LIST_IGNORE;
							}
							return error;
						}}
					>
						<p className="ant-upload-drag-icon">
							<FontAwesomeIcon
								icon={faFileArrowUp}
								className="m-r-xs"
								style={{
									fontSize: "24px",
								}}
							/>
						</p>
						<p className="ant-upload-text">
							Click or drag file to this area to upload
						</p>
						<p className="ant-upload-hint">
							Support for a single or bulk upload
						</p>
					</Upload.Dragger>
				</Form.Item>
				<Form.Item name="type" label="Type" rules={[validateRules.required()]}>
					<Radio.Group>
						<Radio value="With INC">With INC</Radio>
						<Radio value="Without INC">Without INC</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item name="description">
					<FloatTextArea label="Notes" placeholder="Notes" />
				</Form.Item>
				<Form.Item name="signature_status" valuePropName="checked">
					<Checkbox>Attach Signature?</Checkbox>
				</Form.Item>
			</Form>
		</Modal>
	);
}
