import { useEffect } from "react";
import { Button, Col, Flex, Row, Table } from "antd";

import { GET } from "../../../../providers/useAxiosQuery";
import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
} from "../../../../providers/CustomTableFilter";

export default function TableGradeFile(props) {
	const {
		tableFilter,
		setTableFilter,
		handleFacultyLoadSubject,
		isLoadingFacultyLoadSubject,
	} = props;

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/profile?${new URLSearchParams(tableFilter)}`,
		"profile_faculty_load_list",
		() => {}
	);

	useEffect(() => {
		refetchSource();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	return (
		<Row gutter={[20, 20]}>
			<Col xs={24} sm={24} md={24}>
				<Flex className="tbl-top-filter" justify="space-between">
					<div />

					<TablePageSize
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
					/>
				</Flex>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<Flex justify="space-between" className="tbl-top-filter">
					<TableGlobalSearchAnimated
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
					/>

					<Flex gap={15}>
						<TableShowingEntriesV2 />

						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							total={dataSource?.data.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper"
						/>
					</Flex>
				</Flex>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<Table
					id="tblGradeFileList"
					className="ant-table-default ant-table-striped"
					dataSource={dataSource && dataSource.data.data}
					rowKey={(record) => record.id}
					pagination={false}
					bordered={false}
					onChange={onChangeTable}
					scroll={{ x: "max-content" }}
					sticky
				>
					<Table.Column
						title="Full Name"
						key="fullname"
						sorter
						dataIndex="fullname"
						width={200}
					/>

					<Table.Column
						title="Department"
						key="departments"
						dataIndex="departments"
						width={150}
						sorter
					/>

					<Table.Column
						title="Subject"
						key="faculty_loads_subject"
						dataIndex="faculty_loads_subject"
						width={120}
						align="center"
						sorter
						render={(text, record) => (
							<Button
								className="btn-main-primary"
								onClick={() => handleFacultyLoadSubject(record)}
								loading={isLoadingFacultyLoadSubject}
							>
								({text}) Subjects
							</Button>
						)}
					/>
				</Table>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<Flex justify="space-between" className="tbl-bottom-filter">
					<div />

					<Flex gap={15}>
						<TableShowingEntriesV2 />
						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							total={dataSource?.data.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper"
						/>
					</Flex>
				</Flex>
			</Col>
		</Row>
	);
}
