import {
	Button,
	Col,
	Flex,
	Form,
	notification,
	Row,
	Select,
	Table,
} from "antd";
import { GET } from "../../../../providers/useAxiosQuery";
import { useEffect, useState } from "react";
import { apiUrl, role, userData } from "../../../../providers/companyInfo";
import {
	TablePagination,
	TableShowingEntriesV2,
	useTableScrollOnTop,
} from "../../../../providers/CustomTableFilter";

export default function CollapseItemFacultyLoadReportDetails(props) {
	const { location } = props;

	const userdata = userData();

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
		from: "grade_file_report",
		school_year_id: "",
		semester_id: "",
		department_id: "",
		grade_file_report_status: "All",
	});

	const { data: dataSchoolYear } = GET(
		`api/school_year`,
		"school_year_dropdown",
		(res) => {},
		false
	);
	const { data: dataSemester } = GET(
		`api/semester`,
		"semester_dropdown",
		() => {},
		false
	);

	const { data: dataDepartments } = GET(
		`api/department`,
		"department_status",
		(res) => {},
		false
	);

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/profile?${new URLSearchParams(tableFilter)}`,
		"faculty_load_profile_list",
		() => {}
	);

	useEffect(() => {
		refetchSource();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	const onFinish = (values) => {
		console.log("onFinish values: ", values);

		setTableFilter((prevState) => ({
			...prevState,
			department_id: values.department_id ? values.department_id : "",
			school_year_id: values.school_year_id ? values.school_year_id : "",
			semester_id: values.semester_id ? values.semester_id : "",
			grade_file_report_status: values.grade_file_report_status
				? values.grade_file_report_status
				: "",
			page: 1,
			page_size: "50",
		}));
	};

	const handlePrintPdf = () => {
		if (selectedRowKeys.length) {
			let data = {
				...tableFilter,
				ids: selectedRowKeys,
				user_id: userdata.id,
			};
			window.open(
				apiUrl(
					`api/profile_grade_file_report_print?${new URLSearchParams(data)}`
				),
				"_blank"
			);
		} else {
			notification.error({
				message: "Grade File Report",
				description: "Please select at least one record to print.",
			});
		}
	};

	useTableScrollOnTop("tbl_faculty_load_report", location.pathname);

	return (
		<Row gutter={[20, 20]} id="tbl-faculty-load-wrapper">
			<Col xs={24} sm={24} md={24} lg={24}>
				<Form
					onFinish={onFinish}
					initialValues={{
						grade_file_report_status: "All",
					}}
				>
					<Row gutter={[20, 20]}>
						<Col xs={24} sm={24} md={12} lg={6}>
							{role() !== 3 ? (
								<Form.Item name="department_id" noStyle>
									<Select
										placeholder="Department"
										className="w-100"
										size="large"
										allowClear
										options={
											dataDepartments
												? dataDepartments.data.map((item) => {
														return {
															label: item.department_name,
															value: item.id,
														};
												  })
												: []
										}
									/>
								</Form.Item>
							) : null}
						</Col>
						<Col xs={24} sm={24} md={6}>
							<Form.Item name="school_year_id" noStyle>
								<Select
									placeholder="School Year"
									className="w-100"
									size="large"
									allowClear
									options={
										dataSchoolYear && dataSchoolYear.data.length > 0
											? dataSchoolYear.data.map((item) => ({
													label: `${item.sy_from} - ${item.sy_to}`,
													value: item.id,
											  }))
											: []
									}
								/>
							</Form.Item>
						</Col>

						<Col xs={24} sm={24} md={6}>
							<Form.Item name="semester_id" noStyle>
								<Select
									placeholder="Semester"
									className="w-100"
									size="large"
									allowClear
									options={
										dataSemester && dataSemester.data.length > 0
											? dataSemester.data.map((item) => ({
													label: item.semester,
													value: item.id,
											  }))
											: []
									}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={6}>
							<Form.Item name="grade_file_report_status" noStyle>
								<Select
									placeholder="Status"
									className="w-100"
									size="large"
									allowClear
									options={[
										{ label: "All", value: "All" },
										{ label: "Approved", value: "Approved" },
										{ label: "Approval", value: "Uploaded" },
										{ label: "No Upload", value: "NoUpload" },
									]}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Flex justify="space-between" align="center">
								<Button className="btn-main-primary" htmlType="submit">
									Submit
								</Button>

								<Button
									className="btn-main-primary"
									onClick={() => handlePrintPdf()}
								>
									Generate Report
								</Button>
							</Flex>
						</Col>
					</Row>
				</Form>
			</Col>

			<Col xs={24} sm={24} md={24} lg={24}>
				<Table
					id="tbl_faculty_load_report"
					className="ant-table-default ant-table-striped"
					dataSource={
						dataSource && dataSource.data.data ? dataSource.data.data : []
					}
					rowKey={(record) => record.id}
					pagination={false}
					bordered={false}
					onChange={onChangeTable}
					rowSelection={{
						selectedRowKeys,
						onChange: (selectedRowKeys) => {
							setSelectedRowKeys(selectedRowKeys);
						},
					}}
					scroll={{ x: "max-content" }}
					sticky
				>
					<Table.Column
						title="Full Name"
						key="fullname"
						sorter
						dataIndex="fullname"
						width={200}
					/>

					<Table.Column
						title="Department"
						key="departments"
						dataIndex="departments"
						width={150}
						sorter
					/>

					<Table.Column
						title="Subject"
						key="faculty_loads_subject"
						dataIndex="faculty_loads_subject"
						width={120}
						align="center"
						sorter
					/>
					<Table.Column
						title="Approval"
						key="grade_file_approval"
						dataIndex="grade_file_approval"
						width={120}
						align="center"
						sorter
					/>
					<Table.Column
						title="Approved"
						key="grade_file_approved"
						dataIndex="grade_file_approved"
						width={120}
						align="center"
						sorter
					/>
				</Table>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<Flex justify="space-between" className="tbl-bottom-filter">
					<div />

					<Flex gap={15}>
						<TableShowingEntriesV2 />
						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							total={dataSource?.data.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl-faculty-load-wrapper"
						/>
					</Flex>
				</Flex>
			</Col>
		</Row>
	);
}
