import { useEffect, useState } from "react";
import { Row, Button, Col, Table, notification, Popconfirm, Flex } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus } from "@fortawesome/pro-regular-svg-icons";

import { GET, POST } from "../../../../providers/useAxiosQuery";
import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
} from "../../../../providers/CustomTableFilter";
import notificationErrors from "../../../../providers/notificationErrors";
import ModalForm from "./ModalForm";

export default function PageBuilding() {
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [toggleModalForm, setToggleModalForm] = useState({
		open: false,
		data: null,
	});

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "building",
		sort_order: "asc",
		isTrash: 0,
	});

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/building?${new URLSearchParams(tableFilter)}`,
		"building_list"
	);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	useEffect(() => {
		if (dataSource) {
			refetchSource();
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	const { mutate: mutateDeleteBuilding, isLoading: isLoadingDeleteBuilding } =
		POST(`api/building_archived`, "building_list");

	const handleSelectedArchived = () => {
		let data = {
			isTrash: tableFilter.isTrash,
			ids: selectedRowKeys,
		};

		mutateDeleteBuilding(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Building",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Building",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	return (
		<Row gutter={[20, 20]}>
			<Col xs={24} sm={24} md={24} lg={14} xl={12}>
				<Row gutter={[20, 20]}>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Button
							type="primary"
							icon={<FontAwesomeIcon icon={faPlus} />}
							onClick={() =>
								setToggleModalForm({
									open: true,
									data: null,
								})
							}
						>
							Add Building
						</Button>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Flex className="tbl-top-filter" justify="space-between">
							<Flex gap={15}>
								<Button
									className={`min-w-150 ${
										tableFilter.isTrash === 0 ? "active" : ""
									}`}
									type={tableFilter.isTrash === 0 ? "primary" : ""}
									onClick={() => {
										setTableFilter((ps) => ({
											...ps,
											isTrash: 0,
										}));
										setSelectedRowKeys([]);
									}}
								>
									Active
								</Button>

								<Button
									className={`min-w-150 ${
										tableFilter.isTrash === 1 ? "active" : ""
									}`}
									type={tableFilter.isTrash === 1 ? "primary" : ""}
									onClick={() => {
										setTableFilter((ps) => ({
											...ps,
											isTrash: 1,
										}));
										setSelectedRowKeys([]);
									}}
								>
									Archived
								</Button>
							</Flex>

							<TablePageSize
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</Flex>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Flex className="tbl-top-filter" justify="space-between">
							<Flex gap={15}>
								<TableGlobalSearchAnimated
									tableFilter={tableFilter}
									setTableFilter={setTableFilter}
								/>
								{selectedRowKeys.length > 0 && (
									<Popconfirm
										title={
											<>
												Are you sure you want to
												<br />
												{tableFilter.isTrash === 0 ? "archive" : "restore"} the
												selected{" "}
												{selectedRowKeys.length > 1 ? "buildings" : "building"}?
											</>
										}
										okText="Yes"
										cancelText="No"
										onConfirm={() => {
											handleSelectedArchived();
										}}
										disabled={isLoadingDeleteBuilding}
									>
										<Button
											name="btn_active_archive"
											loading={isLoadingDeleteBuilding}
											danger={tableFilter.isTrash === 0}
											type="primary"
											className={tableFilter.isTrash === 1 ? "btn-success" : ""}
										>
											{tableFilter.isTrash === 0 ? "ARCHIVE" : "RESTORE"}{" "}
											SELECTED
										</Button>
									</Popconfirm>
								)}
							</Flex>

							<Flex>
								<TableShowingEntriesV2 />
								<TablePagination
									tableFilter={tableFilter}
									setTableFilter={setTableFilter}
									total={dataSource?.data.total}
									showLessItems={true}
									showSizeChanger={false}
									tblIdWrapper="tbl_wrapper_building"
								/>
							</Flex>
						</Flex>
					</Col>

					<Col xs={24} sm={24} md={24}>
						<Table
							className="ant-table-default ant-table-striped"
							dataSource={dataSource && dataSource.data.data}
							rowKey={(record) => record.id}
							pagination={false}
							bordered={false}
							onChange={onChangeTable}
							scroll={{ x: "max-content" }}
							sticky
							rowSelection={{
								selectedRowKeys,
								onChange: (selectedRowKeys) => {
									setSelectedRowKeys(selectedRowKeys);
								},
							}}
						>
							<Table.Column
								title="Action"
								key="action"
								dataIndex="action"
								align="center"
								width={150}
								render={(text, record) => {
									return (
										<Flex justify="center">
											<Button
												type="link"
												onClick={() =>
													setToggleModalForm({
														open: true,
														data: record,
													})
												}
											>
												<FontAwesomeIcon icon={faPencil} />
											</Button>
										</Flex>
									);
								}}
							/>

							<Table.Column
								title="Building"
								key="building"
								dataIndex="building"
								sorter={true}
								defaultSortOrder="ascend"
							/>
						</Table>
					</Col>

					<Col xs={24} sm={24} md={24}>
						<Flex
							justify="space-between"
							align="center"
							className="tbl-bottom-filter"
						>
							<div />

							<Flex align="center">
								<TableShowingEntriesV2 />
								<TablePagination
									tableFilter={tableFilter}
									setTableFilter={setTableFilter}
									total={dataSource?.data.total}
									showLessItems={true}
									showSizeChanger={false}
									tblIdWrapper="tbl_wrapper"
								/>
							</Flex>
						</Flex>
					</Col>
				</Row>
			</Col>

			<ModalForm
				toggleModalForm={toggleModalForm}
				setToggleModalForm={setToggleModalForm}
			/>
		</Row>
	);
}
