import { useLocation } from "react-router-dom";
import { Col, Collapse, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";

import CollapseItemGradeFileReportDetails from "./components/CollapseItemGradeFileReportDetails";
import CollapseItemFacultyLoadReportDetails from "./components/CollapseItemFacultyLoadReportDetails";

export default function PageGradeFileReports() {
	const location = useLocation();

	return (
		<Row gutter={[20, 20]}>
			<Col xs={24} sm={24} md={24} lg={24}>
				<Collapse
					defaultActiveKey={["1", "2", "3", "4"]}
					expandIconPosition="end"
					expandIcon={({ isActive }) =>
						isActive ? (
							<FontAwesomeIcon icon={faAngleUp} />
						) : (
							<FontAwesomeIcon icon={faAngleDown} />
						)
					}
					className="collapse-main-primary"
					items={[
						{
							key: "1",
							className: "collapse-main-primary",
							label: `Grade File Report Details`,
							children: (
								<CollapseItemGradeFileReportDetails location={location} />
							),
						},
						{
							key: "2",
							className: "collapse-main-primary",
							label: `Faculty Load Report Details`,
							children: (
								<CollapseItemFacultyLoadReportDetails location={location} />
							),
						},
					]}
				/>
			</Col>
		</Row>
	);
}
