import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { Breadcrumb, Layout, Card, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGifts, faHome, faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { lineSpinner } from "ldrs";
import Pusher from "pusher-js";

import { bgColor, name, userData } from "../../providers/companyInfo";
import ClearCache from "../../providers/ClearCache";
import Sidemenu from "./Sidemenu";
import Header from "./Header";
import Footer from "./Footer";

lineSpinner.register();

export default function Private(props) {
	const {
		children,
		moduleName,
		title,
		subtitle,
		breadcrumb,
		pageHeaderIcon,
		pageHeaderClass,
		pageId,
		pageClassName,
		dataPermissions,
		refetchPermissions,
	} = props;

	const queryClient = useQueryClient();
	const location = useLocation();
	const navigate = useNavigate();

	const [width, setWidth] = useState(window.innerWidth);

	const [sideMenuCollapse, setSideMenuCollapse] = useState(
		window.innerWidth <= 768 ? true : false
	);

	useEffect(() => {
		refetchPermissions();

		const section = document.querySelector(".private-layout");
		section.scrollIntoView({ behavior: "smooth", block: "start" });

		document.title = (moduleName ?? title).toUpperCase() + " | " + name;

		function handleResize() {
			setWidth(window.innerWidth);

			if (window.innerWidth === 768) {
				setSideMenuCollapse(true);
			}
			if (window.innerWidth > 768) {
				setSideMenuCollapse(false);
			}
		}
		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
		// eslint-disable-next-line
	}, [title, moduleName, location]);

	useEffect(() => {
		// Enable pusher logging - don't include this in production
		Pusher.logToConsole = true;

		let pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
			cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
		});

		let channel = pusher.subscribe(process.env.REACT_APP_PUSHER_APP_CHANNEL);
		channel.bind(
			process.env.REACT_APP_PUSHER_APP_CHANNEL,
			function (receive_message) {
				let message = receive_message.message;
				if (
					message.type === "notification" &&
					message.user_id === userData().id &&
					window.location.origin === message.link_origin
				) {
					queryClient.refetchQueries("user_notifications");
				} else if (
					message.type === "message" &&
					message.user_id === userData().id &&
					window.location.origin === message.link_origin
				) {
					queryClient.refetchQueries("user_messages");
				}
			}
		);

		return () => {
			channel.unbind(process.env.REACT_APP_PUSHER_APP_CHANNEL);
			pusher.unsubscribe(process.env.REACT_APP_PUSHER_APP_CHANNEL);
		};
	}, [queryClient]);

	return (
		<ClearCache>
			{({ isLatestVersion, emptyCacheStorage }) => (
				<>
					{!isLatestVersion && (
						<div className="notification-notice">
							<div className="notification-notice-content">
								<div className="notification-notice-icon">
									<FontAwesomeIcon icon={faGifts} />
								</div>
								<div className="notification-notice-message">
									<div className="title">Updates Now Available</div>
									<div className="description">
										A new version of this Web App is ready
									</div>
									<div className="action">
										<Button
											onClick={(e) => {
												e.preventDefault();
												emptyCacheStorage();
											}}
											icon={<FontAwesomeIcon icon={faRefresh} />}
										>
											Refresh
										</Button>
									</div>
								</div>
							</div>
						</div>
					)}

					<div className="globalLoading hide">
						<l-line-spinner size="50" stroke="5" speed="1" color={bgColor} />
					</div>

					<Layout
						hasSider
						className={`private-layout ${pageClassName ?? ""}`}
						id={pageId ?? ""}
					>
						<Sidemenu
							location={location}
							sideMenuCollapse={sideMenuCollapse}
							setSideMenuCollapse={setSideMenuCollapse}
							width={width}
							dataPermissions={dataPermissions}
						/>

						<Layout
							className={sideMenuCollapse ? "ant-layout-has-collapse" : ""}
						>
							<Header
								sideMenuCollapse={sideMenuCollapse}
								setSideMenuCollapse={setSideMenuCollapse}
								width={width}
								pageHeaderClass={pageHeaderClass}
								pageHeaderIcon={pageHeaderIcon}
								title={title}
								subtitle={subtitle}
							/>

							<Layout.Content
								onClick={() => {
									if (width <= 767) {
										setSideMenuCollapse(true);
									}
								}}
							>
								<Breadcrumb
									separator={<span className="arrow" />}
									items={[
										{
											title: (
												<Link to="/">
													<FontAwesomeIcon icon={faHome} />
												</Link>
											),
										},
										...(breadcrumb && breadcrumb.length > 0
											? breadcrumb.map((item, index) => {
													let colorRed = "";
													if (breadcrumb.length > 1) {
														if (breadcrumb.length === index + 1) {
															colorRed = "breadcrumb-item-last";
														}
													}
													return {
														title: item.name,
														className: `cursor-pointer font-14px breadcrumb-item-text ${colorRed} ${
															item.className ? ` ${item.className}` : ""
														}`,
														id: item.id ?? "",
														onClick: () => {
															if (item.link) {
																navigate(item.link);
															}
														},
													};
											  })
											: []),
									]}
								/>

								<Card>{children}</Card>
							</Layout.Content>

							<Footer />
						</Layout>
					</Layout>
				</>
			)}
		</ClearCache>
	);
}
