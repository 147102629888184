import { useEffect, useState } from "react";
import { Button, Col, Flex, Row, Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileCirclePlus } from "@fortawesome/pro-regular-svg-icons";

import { GET } from "../../../../providers/useAxiosQuery";
import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
} from "../../../../providers/CustomTableFilter";
import { apiUrl } from "../../../../providers/companyInfo";
import ModalUploadFile from "./ModalUploadFile";
import ModalUploadFileStatusPreview from "./ModalUploadFileStatusPreview";

export default function TableGradeFileFaculty(props) {
	const { tableFilter, setTableFilter } = props;

	const [toggleModalFormUploadFile, setToggleModalFormUploadFile] = useState({
		open: false,
		data: null,
	});
	const [toggleModalFormUploadFileShow, setToggleModalFormUploadFileShow] =
		useState({
			open: false,
			data: null,
		});

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/faculty_load?${new URLSearchParams(tableFilter)}`,
		"faculty_load_list",
		() => {}
	);

	useEffect(() => {
		refetchSource();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	return (
		<Row gutter={[15, 15]} id="tbl_wrapper">
			<Col xs={24} sm={24} md={24}>
				<Flex justify="space-between" className="tbl-top-filter">
					<div />

					<TablePageSize
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
					/>
				</Flex>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<Flex justify="space-between" className="tbl-top-filter">
					<TableGlobalSearchAnimated
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
					/>

					<Flex gap={15}>
						<TableShowingEntriesV2 />

						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							total={dataSource?.data.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper"
						/>
					</Flex>
				</Flex>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<Table
					id="tblGradeFileList"
					className="ant-table-default ant-table-striped"
					dataSource={dataSource && dataSource.data.data}
					rowKey={(record) => record.id}
					pagination={false}
					bordered={false}
					onChange={onChangeTable}
					scroll={{ x: "max-content" }}
					sticky
				>
					<Table.Column
						title="Upload Grade"
						key="upload_grade"
						align="center"
						width={100}
						render={(text, record) => {
							return (
								<Button
									type="link"
									className="color-1"
									onClick={() => {
										setToggleModalFormUploadFile({
											open: true,
											data: record,
										});
									}}
									disabled={record.gradeFileStatus === "Approved"}
									icon={<FontAwesomeIcon icon={faFileCirclePlus} />}
								/>
							);
						}}
					/>

					<Table.Column
						title="Section"
						key="section"
						dataIndex="section"
						width={100}
						sorter
					/>
					<Table.Column
						title="Subject"
						key="code"
						dataIndex="code"
						width={120}
						sorter
					/>

					<Table.Column
						title="Room"
						key="room_code"
						dataIndex="room_code"
						width={120}
						sorter
					/>

					<Table.Column
						title="School Year"
						key="school_year"
						dataIndex="school_year"
						width={150}
						sorter
					/>

					<Table.Column
						title="Semester"
						key="semester"
						dataIndex="semester"
						width={150}
						sorter
					/>
					<Table.Column
						title="Status"
						key="gradeFileStatus"
						dataIndex="gradeFileStatus"
						align="center"
						width={100}
						sorter
					/>

					<Table.Column
						title="Remarks"
						key="gradeFileRemarks"
						dataIndex="gradeFileRemarks"
						width={150}
						sorter
					/>
					<Table.Column
						title="Preview Attachment"
						key="preview_attachment"
						align="center"
						width={120}
						render={(_, record) => {
							return (
								<Button
									type="link"
									className="color-1"
									onClick={() => {
										let grade_files = record.grade_files;
										let profile = record.profile;
										let attachmentsSig = profile.attachments.filter(
											(f) => f.file_description === "Signature"
										);
										let pdf_file = null;
										let grade_file = {};

										if (grade_files.length > 0) {
											grade_file = grade_files[0];
											let attachments = grade_files[0].attachments.filter(
												(f) => f.file_description === "Grade File"
											);

											if (attachments.length > 0) {
												pdf_file = attachments[0].pdf_file;
											}
										}

										let signature = null;

										if (attachmentsSig.length > 0) {
											signature = apiUrl(attachmentsSig[0].file_path);
										}

										setToggleModalFormUploadFileShow({
											open: true,
											data: { ...grade_file, pdf_file, signature },
										});
									}}
								>
									<FontAwesomeIcon icon={faEye} />
								</Button>
							);
						}}
					/>
				</Table>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<Flex justify="space-between" className="tbl-bottom-filter">
					<div />

					<Flex gap={15}>
						<TableShowingEntriesV2 />
						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							total={dataSource?.data.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper"
						/>
					</Flex>
				</Flex>
			</Col>

			<ModalUploadFile
				toggleModalFormUploadFile={toggleModalFormUploadFile}
				setToggleModalFormUploadFile={setToggleModalFormUploadFile}
			/>
			<ModalUploadFileStatusPreview
				toggleModalFormUploadFileShow={toggleModalFormUploadFileShow}
				setToggleModalFormUploadFileShow={setToggleModalFormUploadFileShow}
			/>
		</Row>
	);
}
