import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Col, Flex, Form, Row, Table } from "antd";

import { GET, POST } from "../../../providers/useAxiosQuery";
import { userData } from "../../../providers/companyInfo";
import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
	useTableScrollOnTop,
} from "../../../providers/CustomTableFilter";
import ModalUploadFile from "./components/ModalUploadFile";
import ModalUploadFileStatus from "./components/ModalUploadFileStatus";
import ModalUploadFileStatusPreview from "./components/ModalUploadFileStatusPreview";
import ModalFacultyLoad from "./components/ModalFacultyLoad";
import FloatSelect from "../../../providers/FloatSelect";

export default function PageGradeFileStatus() {
	const location = useLocation();

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const [toggleModalFormUploadFile, setToggleModalFormUploadFile] = useState({
		open: false,
		data: null,
	});
	const [toggleModalFormUploadFileStatus, setToggleModalFormUploadFileStatus] =
		useState(false);
	const [toggleModalFormUploadFileShow, setToggleModalFormUploadFileShow] =
		useState({
			open: false,
			data: null,
		});

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
		grade_file_status:
			location.pathname === "/grade-tracker/approval" ? "Approval" : "Approved",
		user_id: userData().id,
		from: "grade_file_status",
		grade_file_type: "Without INC",
		school_year_id: "",
		semester_id: "",
		department_id: "",
	});

	const [toggleModalFacultyLoad, setToggleModalFacultyLoad] = useState({
		open: false,
		data: null,
		fullname: null,
	});

	useEffect(() => {
		let grade_file_status = "";

		if (location.pathname === "/grade-tracker/approval") {
			grade_file_status = "Approval";
		} else if (location.pathname === "/grade-tracker/approved") {
			grade_file_status = "Approved";
		}

		setTableFilter({
			page: 1,
			page_size: 50,
			search: "",
			sort_field: "created_at",
			sort_order: "desc",
			grade_file_status,
			user_id: userData().id,
			from: "grade_file_status",
			grade_file_type: "Without INC",
			profile_ids: [],
			school_year_id: "",
			semester_id: "",
			department_id: "",
		});

		return () => {};
	}, [location]);

	const { data: dataSchoolYear } = GET(
		`api/school_year`,
		"school_year_dropdown",
		(res) => {},
		false
	);
	const { data: dataSemester } = GET(`api/semester`, "semester_dropdown");
	const { data: dataDepartments } = GET(
		`api/department?from=grade_file`,
		"department_dropdown",
		(res) => {},
		false
	);

	const { data: dataSourceFaculty } = GET(
		`api/profile?from=grade_file`,
		"faculty_load_profile_list",
		(res) => {},
		false
	);

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/profile?${new URLSearchParams(tableFilter)}`,
		"faculty_load_profile_status_list"
	);

	useEffect(() => {
		if (dataSource) {
			refetchSource();
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	const {
		mutate: mutateFacultyLoadSubject,
		isLoading: isLoadingFacultyLoadSubject,
	} = POST(`api/faculty_load_subjects`, "faculty_load_subjects");

	const handleFacultyLoadSubject = (record, grade_file_status) => {
		mutateFacultyLoadSubject(
			{
				profile_id: record.id,
				school_year_id: tableFilter.school_year_id,
				semester_id: tableFilter.semester_id,
				from: "grade_file",
				grade_file_status,
			},
			{
				onSuccess: (res) => {
					if (res.success) {
						setToggleModalFacultyLoad({
							open: true,
							data: res.data,
							fullname: record.fullname,
						});
					}
				},
			}
		);
	};

	useTableScrollOnTop("tblGradeFileApprovalList", location.pathname);

	return (
		<Row gutter={[15, 15]} id="tbl_wrapper">
			<Col xs={24} sm={24} md={24}>
				<Form>
					<Row gutter={[20, 0]}>
						<Col xs={24} sm={24} md={6}>
							<Form.Item name="profile_ids" noStyle>
								<FloatSelect
									label="Faculty"
									placeholder="Faculty"
									allowClear
									multi="multiple"
									options={
										dataSourceFaculty && dataSourceFaculty.data.length > 0
											? dataSourceFaculty.data.map((item) => ({
													label: item.fullname,
													value: item.id,
											  }))
											: []
									}
									onChange={(e) =>
										setTableFilter((ps) => ({
											...ps,
											profile_ids: e ? e : [],
										}))
									}
								/>
							</Form.Item>
						</Col>

						<Col xs={24} sm={24} md={6}>
							<Form.Item name="school_year_id" noStyle>
								<FloatSelect
									label="School Year"
									placeholder="School Year"
									allowClear
									options={
										dataSchoolYear && dataSchoolYear.data.length > 0
											? dataSchoolYear.data.map((item) => ({
													label: `${item.sy_from} - ${item.sy_to}`,
													value: item.id,
											  }))
											: []
									}
									onChange={(e) =>
										setTableFilter((ps) => ({
											...ps,
											school_year_id: e ? e : "",
										}))
									}
								/>
							</Form.Item>
						</Col>

						<Col xs={24} sm={24} md={6}>
							<Form.Item name="semester_id" noStyle>
								<FloatSelect
									label="Semester"
									placeholder="Semester"
									allowClear
									options={
										dataSemester && dataSemester.data.length > 0
											? dataSemester.data.map((item) => ({
													label: item.semester,
													value: item.id,
											  }))
											: []
									}
									onChange={(e) =>
										setTableFilter((ps) => ({
											...ps,
											semester_id: e ? e : "",
										}))
									}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={6}>
							<Form.Item name="department_id" noStyle>
								<FloatSelect
									label="Department Name"
									placeholder="Department Name"
									allowClear
									options={
										dataDepartments && dataDepartments.data.length > 0
											? dataDepartments.data.map((item) => ({
													label: item.department_name,
													value: item.id,
											  }))
											: []
									}
									onChange={(e) =>
										setTableFilter((ps) => ({
											...ps,
											department_id: e ? e : "",
										}))
									}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Col>
			{location.pathname === "/grade-tracker/approval" && (
				<Col xs={24} sm={24} md={24}>
					<Flex justify="space-between" className="tbl-top-filter">
						<Flex gap={15}>
							<Flex gap={15}>
								<Button
									className={`btn-main-primary min-w-150 ${
										tableFilter.grade_file_type === "Without INC"
											? "active"
											: "outlined"
									}`}
									onClick={() => {
										setTableFilter((ps) => ({
											...ps,
											grade_file_type: "Without INC",
										}));
									}}
								>
									Without INC
								</Button>

								<Button
									className={`btn-main-primary min-w-150 ${
										tableFilter.grade_file_type === "With INC"
											? "active"
											: "outlined"
									}`}
									onClick={() => {
										setTableFilter((ps) => ({
											...ps,
											grade_file_type: "With INC",
										}));
									}}
								>
									With INC
								</Button>
							</Flex>

							{selectedRowKeys.length > 0 ? (
								<Button
									className="btn-main-primary"
									onClick={() => setToggleModalFormUploadFileStatus(true)}
								>
									Update Status
								</Button>
							) : (
								<div />
							)}
						</Flex>

						<TablePageSize
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
						/>
					</Flex>
				</Col>
			)}

			<Col xs={24} sm={24} md={24}>
				<Flex className="tbl-top-filter" gap={15} justify="space-between">
					<TableGlobalSearchAnimated
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
					/>

					<Flex gap={15}>
						<TableShowingEntriesV2 />

						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							total={dataSource?.data.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper"
						/>
					</Flex>
				</Flex>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<Table
					id="tblGradeFileApprovalList"
					className="ant-table-default ant-table-striped"
					dataSource={dataSource && dataSource.data.data}
					rowKey={(record) => record.id}
					pagination={false}
					bordered={false}
					onChange={onChangeTable}
					scroll={{ x: "max-content" }}
					sticky
					// rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys }}
				>
					<Table.Column
						title="Name"
						key="fullname"
						sorter
						dataIndex="fullname"
						width={200}
					/>

					<Table.Column
						title="Department"
						key="departments"
						dataIndex="departments"
						width={150}
						sorter
					/>

					<Table.Column
						title="Subject"
						key="faculty_loads_subject"
						dataIndex="faculty_loads_subject"
						render={(text, record) => {
							let count = 0;

							if (location.pathname === "/grade-tracker/approval") {
								count = Number(text) - record.grade_file_approval;
							} else {
								count = Number(text) - record.grade_file_approved;
							}

							return (
								<Button
									className="btn-main-primary"
									onClick={() =>
										handleFacultyLoadSubject(
											record,
											location.pathname === "/grade-tracker/approval"
												? "No Upload"
												: "No Upload,Uploaded"
										)
									}
									loading={isLoadingFacultyLoadSubject}
								>
									({count}) Subjects
								</Button>
							);
						}}
						width={120}
						align="center"
						sorter
					/>
					<Table.Column
						title={
							location.pathname === "/grade-tracker/approval"
								? "Uploaded"
								: "Approved"
						}
						key={`${
							location.pathname === "/grade-tracker/approval"
								? "grade_file_approval"
								: "grade_file_approved"
						}`}
						dataIndex={`${
							location.pathname === "/grade-tracker/approval"
								? "grade_file_approval"
								: "grade_file_approved"
						}`}
						width={150}
						align="center"
						sorter
						render={(text, record) => (
							<Button
								className="btn-success"
								onClick={() =>
									handleFacultyLoadSubject(
										record,
										location.pathname === "/grade-tracker/approval"
											? "Uploaded"
											: "Approved"
									)
								}
								loading={isLoadingFacultyLoadSubject}
							>
								({text ? text : 0}) Files
							</Button>
						)}
					/>
				</Table>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<Flex justify="space-between" className="tbl-bottom-filter">
					<div />

					<Flex gap={15}>
						<TableShowingEntriesV2 />
						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							total={dataSource?.data.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper"
						/>
					</Flex>
				</Flex>
			</Col>

			<ModalUploadFile
				toggleModalFormUploadFile={toggleModalFormUploadFile}
				setToggleModalFormUploadFile={setToggleModalFormUploadFile}
			/>
			<ModalUploadFileStatus
				toggleModalFormUploadFileStatus={toggleModalFormUploadFileStatus}
				setToggleModalFormUploadFileStatus={setToggleModalFormUploadFileStatus}
				selectedRowKeys={selectedRowKeys}
				setSelectedRowKeys={setSelectedRowKeys}
			/>
			<ModalUploadFileStatusPreview
				toggleModalFormUploadFileShow={toggleModalFormUploadFileShow}
				setToggleModalFormUploadFileShow={setToggleModalFormUploadFileShow}
			/>
			<ModalFacultyLoad
				toggleModalFacultyLoad={toggleModalFacultyLoad}
				setToggleModalFacultyLoad={setToggleModalFacultyLoad}
				from={`grade_file_status_${
					location.pathname === "/grade-tracker/approval"
						? "approval"
						: "approved"
				}`}
			/>
		</Row>
	);
}
