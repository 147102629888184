import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Form, Row } from "antd";

import { GET, POST } from "../../../providers/useAxiosQuery";
import { userData } from "../../../providers/companyInfo";
import { useTableScrollOnTop } from "../../../providers/CustomTableFilter";
import FloatSelect from "../../../providers/FloatSelect";
import ModalFacultyLoad from "./components/ModalFacultyLoad";
import TableGradeFile from "./components/TableGradeFile";
import TableGradeFileFaculty from "./components/TableGradeFileFaculty";

export default function PageGradeFile() {
	const location = useLocation();

	const userdata = userData();

	const [toggleModalFacultyLoad, setToggleModalFacultyLoad] = useState({
		open: false,
		data: null,
		fullname: null,
	});
	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
		from: "grade_file",
		profile_ids: [],
		school_year_id: "",
		semester_id: "",
		department_id: "",
	});

	const { data: dataSchoolYear } = GET(
		`api/school_year`,
		"school_year_dropdown",
		(res) => {},
		false
	);
	const { data: dataSemester } = GET(
		`api/semester`,
		"semester_dropdown",
		() => {},
		false
	);
	const { data: dataDepartments } = GET(
		`api/department?from=grade_file`,
		"department_dropdown",
		(res) => {},
		false
	);

	const { data: dataSourceFaculty } = GET(
		`api/profile?from=grade_file`,
		"faculty_load_profile_list",
		(res) => {},
		false
	);

	const {
		mutate: mutateFacultyLoadSubject,
		isLoading: isLoadingFacultyLoadSubject,
	} = POST(`api/faculty_load_subjects`, "faculty_load_subjects");

	const handleFacultyLoadSubject = (record) => {
		mutateFacultyLoadSubject(
			{
				profile_id: record.id,
				school_year_id: tableFilter.school_year_id,
				semester_id: tableFilter.semester_id,
				from: "grade_file",
			},
			{
				onSuccess: (res) => {
					if (res.success) {
						setToggleModalFacultyLoad({
							open: true,
							data: res.data,
							fullname: record.fullname,
						});
					}
				},
			}
		);
	};

	useTableScrollOnTop("tblGradeFileList", location.pathname);

	return (
		<Row gutter={[20, 20]} id="tbl_wrapper">
			<Col xs={24} sm={24} md={24}>
				<Form>
					<Row gutter={[20, 0]}>
						{userdata.user_role_id !== 5 ? (
							<Col xs={24} sm={24} md={6}>
								<Form.Item name="profile_ids" noStyle>
									<FloatSelect
										label="Faculty"
										placeholder="Faculty"
										allowClear
										multi="multiple"
										options={
											dataSourceFaculty && dataSourceFaculty.data.length > 0
												? dataSourceFaculty.data.map((item) => ({
														label: item.fullname,
														value: item.id,
												  }))
												: []
										}
										onChange={(e) =>
											setTableFilter((ps) => ({
												...ps,
												profile_ids: e ? e : [],
											}))
										}
									/>
								</Form.Item>
							</Col>
						) : null}

						<Col xs={24} sm={24} md={6}>
							<Form.Item name="school_year_id" noStyle>
								<FloatSelect
									label="School Year"
									placeholder="School Year"
									allowClear
									options={
										dataSchoolYear && dataSchoolYear.data.length > 0
											? dataSchoolYear.data.map((item) => ({
													label: `${item.sy_from} - ${item.sy_to}`,
													value: item.id,
											  }))
											: []
									}
									onChange={(e) =>
										setTableFilter((ps) => ({
											...ps,
											school_year_id: e ? e : "",
										}))
									}
								/>
							</Form.Item>
						</Col>

						<Col xs={24} sm={24} md={6}>
							<Form.Item name="semester_id" noStyle>
								<FloatSelect
									label="Semester"
									placeholder="Semester"
									allowClear
									options={
										dataSemester && dataSemester.data.length > 0
											? dataSemester.data.map((item) => ({
													label: item.semester,
													value: item.id,
											  }))
											: []
									}
									onChange={(e) =>
										setTableFilter((ps) => ({
											...ps,
											semester_id: e ? e : "",
										}))
									}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={6}>
							<Form.Item name="department_id" noStyle>
								<FloatSelect
									label="Department Name"
									placeholder="Department Name"
									allowClear
									options={
										dataDepartments && dataDepartments.data.length > 0
											? dataDepartments.data.map((item) => ({
													label: item.department_name,
													value: item.id,
											  }))
											: []
									}
									onChange={(e) =>
										setTableFilter((ps) => ({
											...ps,
											department_id: e ? e : "",
										}))
									}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Col>

			<Col xs={24} sm={24} md={24}>
				{userdata.user_role_id === 5 ? (
					<TableGradeFileFaculty
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
					/>
				) : (
					<TableGradeFile
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
						handleFacultyLoadSubject={handleFacultyLoadSubject}
						isLoadingFacultyLoadSubject={isLoadingFacultyLoadSubject}
					/>
				)}
			</Col>

			<ModalFacultyLoad
				toggleModalFacultyLoad={toggleModalFacultyLoad}
				setToggleModalFacultyLoad={setToggleModalFacultyLoad}
				from="grade_file"
			/>
		</Row>
	);
}
