import {
	Button,
	Col,
	Flex,
	Form,
	notification,
	Row,
	Select,
	Table,
} from "antd";
import {
	TablePagination,
	TableShowingEntriesV2,
	useTableScrollOnTop,
} from "../../../../providers/CustomTableFilter";
import { useEffect, useState } from "react";
import { GET } from "../../../../providers/useAxiosQuery";
import { apiUrl, role } from "../../../../providers/companyInfo";

export default function CollapseItemGradeFileReportDetails(props) {
	const { location } = props;

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "fullname",
		sort_order: "desc",
		isTrash: 0,
		type: "Without INC",
		department_id: "",
		status: "",
	});

	const { data: dataDepartments } = GET(
		`api/department`,
		"department_status",
		(res) => {},
		false
	);

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/grade_file?${new URLSearchParams(tableFilter)}`,
		"grade_file_report_list",
		() => {}
	);

	useEffect(() => {
		refetchSource();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	const onFinish = (values) => {
		setTableFilter((prevState) => ({
			...prevState,
			...values,
			page: 1,
			page_size: "50",
		}));
	};

	const handlePrintPdf = () => {
		if (selectedRowKeys.length) {
			let data = {
				...tableFilter,
				ids: selectedRowKeys,
			};
			window.open(
				apiUrl(`api/grade_file_report?${new URLSearchParams(data)}`),
				"_blank"
			);
		} else {
			notification.error({
				message: "Grade File Report",
				description: "Please select at least one record to print.",
			});
		}
	};

	useTableScrollOnTop("tbl_grade_file", location.pathname);

	return (
		<Row gutter={[20, 20]} id="tbl-grade-file-wrapper">
			<Col xs={24} sm={24} md={24} lg={24}>
				<Form onfinish={onFinish}>
					<Row gutter={[20, 20]}>
						<Col xs={24} sm={24} md={12} lg={6}>
							{role() !== 3 ? (
								<Select
									placeholder="Department"
									className="w-100"
									size="large"
									allowClear
									value={
										tableFilter.department_id ? tableFilter.department_id : null
									}
									options={
										dataDepartments
											? dataDepartments.data.map((item) => {
													return {
														label: item.department_name,
														value: item.id,
													};
											  })
											: []
									}
									onChange={(value) => {
										setTableFilter((prevState) => ({
											...prevState,
											department_id: value ? value : "",
										}));
									}}
								/>
							) : null}
						</Col>
						<Col xs={24} sm={24} md={12} lg={6}>
							<Select
								placeholder="Type"
								className="w-100"
								size="large"
								allowClear
								value={tableFilter.type ? tableFilter.type : null}
								options={[
									{ label: "Without INC", value: "Without INC" },
									{ label: "With INC", value: "With INC" },
								]}
								onChange={(value) => {
									setTableFilter((prevState) => ({
										...prevState,
										type: value ? value : "",
									}));
								}}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={6}>
							<Select
								placeholder="Status"
								className="w-100"
								size="large"
								allowClear
								value={tableFilter.status ? tableFilter.status : null}
								options={[
									{ label: "Approved", value: "Approved" },
									{ label: "Approval", value: "Approval" },
									{ label: "Uploaded", value: "Uploaded" },
								]}
								onChange={(value) => {
									setTableFilter((prevState) => ({
										...prevState,
										status: value ? value : "",
									}));
								}}
							/>
						</Col>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Flex justify="space-between" align="center">
								<Button className="btn-main-primary" htmlType="submit">
									Submit
								</Button>

								<Button
									className="btn-main-primary"
									onClick={() => handlePrintPdf()}
								>
									Generate Report
								</Button>
							</Flex>
						</Col>
					</Row>
				</Form>
			</Col>

			<Col xs={24} sm={24} md={14} lg={24}>
				<Table
					id="tbl_grade_file"
					className="ant-table-default ant-table-striped"
					dataSource={dataSource && dataSource.data ? dataSource.data.data : []}
					rowKey={(record) => record.id}
					pagination={false}
					bordered={false}
					onChange={onChangeTable}
					scroll={{ x: "max-content" }}
					rowSelection={{
						selectedRowKeys,
						onChange: (selectedRowKeys) => {
							setSelectedRowKeys(selectedRowKeys);
						},
					}}
					sticky
				>
					<Table.Column
						title="Name"
						key="fullname"
						sorter
						dataIndex="fullname"
						width={220}
					/>
					<Table.Column
						title="Section"
						key="section"
						dataIndex="section"
						sorter
						width={180}
					/>
					<Table.Column
						title="Subject"
						key="code"
						dataIndex="code"
						sorter
						width={180}
					/>
					<Table.Column
						title="School Year"
						key="school_year"
						dataIndex="school_year"
						sorter
						width={180}
					/>
					<Table.Column
						title="Semester"
						key="semester"
						dataIndex="semester"
						sorter
						width={180}
					/>
					<Table.Column
						title="Status"
						key="status"
						dataIndex="status"
						sorter
						align="center"
						width={180}
					/>
				</Table>
			</Col>
			<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
				<Flex justify="space-between" align="center" className="tbl-top-filter">
					<div />
					<Flex gap={10} align="center">
						<TableShowingEntriesV2 />

						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							total={
								dataSource && dataSource.data && dataSource.data.total > 0
									? dataSource.data.total
									: 0
							}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl-grade-file-wrapper"
						/>
					</Flex>
				</Flex>
			</Col>
		</Row>
	);
}
