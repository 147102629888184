import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Row, Button, Col, Table, Form, Tooltip, Select, Flex } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBalanceScale,
	faCheckCircle,
	faFileSpreadsheet,
	faFolderImage,
} from "@fortawesome/pro-regular-svg-icons";

import { GET } from "../../../providers/useAxiosQuery";
import { role } from "../../../providers/companyInfo";
import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
	useTableScrollOnTop,
} from "../../../providers/CustomTableFilter";
import ModalFormReportUpdateRemarks from "./components/ModalFormReportUpdateRemarks";
import ModalFormFacultyLoadEndorseForApproval from "./components/ModalFormFacultyLoadEndorseForApproval";
import ModalFormFacultyLoadJustificationAttachment from "./components/ModalFormFacultyLoadJustificationAttachment";
import ModalFormExcelPrint from "./components/ModalFormExcelPrint";

export default function PageFacultyLoadAbsentList() {
	const location = useLocation();

	const [form] = Form.useForm();

	const [toggleModalExcelPrint, setToggleModalExcelPrint] = useState(false);

	const [toggleModalFormUpdateRemarks, setToggleModalFormUpdateRemarks] =
		useState({
			open: false,
			data: null,
		});

	const [
		toggleModalFormIndorseForApproval,
		setToggleModalFormIndorseForApproval,
	] = useState({
		open: false,
		data: null,
	});

	const [
		toggleModalFormJustificationAttachment,
		setToggleModalFormJustificationAttachment,
	] = useState({
		open: false,
		data: [],
	});

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at_format",
		sort_order: "desc",
		isTrash: 0,
		from: location.pathname,
		building_id: [],
		floor_id: [],
		room_id: [],
		status_id: "",
		department_id: "",
	});

	useEffect(() => {
		setTableFilter((prevState) => ({
			...prevState,
			from: location.pathname,
		}));

		return () => {};
	}, [location]);

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/faculty_load_monitoring?${new URLSearchParams(tableFilter)}`,
		"faculty_load_monitoring_list",
		(res) => {}
	);

	const { data: dataBuildings } = GET(
		"api/building",
		"building_select",
		(res) => {},
		false
	);

	const { data: dataFloors } = GET(
		`api/floor`,
		"floor_select",
		(res) => {},
		false
	);

	const [roomFilter, setRoomFilter] = useState({
		from: "PageFacultyMonitoring",
		building_id: "",
		floor_id: "",
	});

	const { data: dataRooms, refetch: refetchRooms } = GET(
		`api/room?${new URLSearchParams(roomFilter)}`,
		"room_selectss",
		(res) => {},
		false
	);

	const { data: dataStatus } = GET(
		`api/status?status_category_id=1`,
		"status_selectss",
		(res) => {},
		false
	);

	const { data: dataDepartments } = GET(
		`api/department`,
		"department_status",
		(res) => {},
		false
	);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((prevState) => ({
			...prevState,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	useEffect(() => {
		if (dataSource) {
			refetchSource();
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	useEffect(() => {
		refetchRooms();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roomFilter]);

	useTableScrollOnTop("tblFacultyLoadAbsentList", location.pathname);

	return (
		<Row gutter={[15, 10]} id="tbl_wrapper">
			<Col xs={24} sm={24} md={24} lg={24} className="filter-select">
				<Form form={form}>
					<Form.Item name="building_id" noStyle>
						<Select
							label="Building"
							placeholder="Building"
							className="w-100 filter"
							size="large"
							showSearch
							allowClear
							options={
								dataBuildings
									? dataBuildings.data.map((item) => {
											return {
												label: item.building,
												value: item.id,
											};
									  })
									: []
							}
							onChange={(value) => {
								setTableFilter((prevState) => ({
									...prevState,
									building_id: value ? value : "",
								}));
								setRoomFilter((prevState) => ({
									...prevState,
									building_id: value ? value : "",
								}));
								form.resetFields(["room_id"]);
							}}
						/>
					</Form.Item>

					<Form.Item name="floor_id" noStyle>
						<Select
							label="Floor"
							placeholder="Floor"
							className="w-100 filter"
							size="large"
							allowClear
							options={
								dataFloors
									? dataFloors.data.map((item) => {
											return {
												label: item.floor,
												value: item.id,
											};
									  })
									: []
							}
							onChange={(value) => {
								setTableFilter((prevState) => ({
									...prevState,
									floor_id: value ? value : "",
								}));
								setRoomFilter((prevState) => ({
									...prevState,
									floor_id: value ? value : "",
								}));
								form.resetFields(["room_id"]);
							}}
						/>
					</Form.Item>

					<Form.Item name="room_id" noStyle>
						<Select
							label="Room"
							placeholder="Room"
							className="w-100 filter"
							size="large"
							allowClear
							options={
								dataRooms
									? dataRooms.data.map((item) => {
											return {
												label: item.room_code,
												value: item.id,
											};
									  })
									: []
							}
							onChange={(value) => {
								setTableFilter((prevState) => ({
									...prevState,
									room_id: value ? value : "",
								}));
							}}
						/>
					</Form.Item>

					{location.pathname !== "/faculty-load/present-list" && (
						<Form.Item name="status_id" noStyle>
							<Select
								label="Status"
								placeholder="Status"
								className="w-100 filter"
								size="large"
								allowClear
								options={
									dataStatus
										? dataStatus.data.map((item) => {
												return {
													label: item.status,
													value: item.id,
												};
										  })
										: []
								}
								onChange={(value) => {
									setTableFilter((prevState) => ({
										...prevState,
										status_id: value ? value : "",
									}));
								}}
							/>
						</Form.Item>
					)}

					{role() !== 3 ? (
						<Form.Item name="department_id" noStyle>
							<Select
								label="Department"
								placeholder="Department"
								className="w-100 filter"
								size="large"
								allowClear
								options={
									dataDepartments
										? dataDepartments.data.map((item) => {
												return {
													label: item.department_name,
													value: item.id,
												};
										  })
										: []
								}
								onChange={(value) => {
									setTableFilter((prevState) => ({
										...prevState,
										department_id: value ? value : "",
									}));
								}}
							/>
						</Form.Item>
					) : null}
				</Form>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<Flex justify="space-between" align="center" className="tbl-top-filter">
					<Button
						className="btn-main-primary"
						icon={<FontAwesomeIcon icon={faFileSpreadsheet} className="mr-5" />}
						onClick={() => setToggleModalExcelPrint(true)}
						name="btn_print"
					>
						Excel Print
					</Button>

					<TablePageSize
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
					/>
				</Flex>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<Flex justify="space-between" align="center" className="tbl-top-filter">
					<Flex gap={15}>
						<TableGlobalSearchAnimated
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
						/>
					</Flex>

					<Flex gap={15}>
						<TableShowingEntriesV2 />

						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							total={dataSource?.data.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper"
						/>
					</Flex>
				</Flex>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<Table
					id="tblFacultyLoadAbsentList"
					className="ant-table-default ant-table-striped"
					dataSource={dataSource && dataSource.data.data}
					rowKey={(record) => record.id}
					pagination={false}
					bordered={false}
					onChange={onChangeTable}
					scroll={{ x: "max-content" }}
				>
					<Table.Column
						title="Action"
						key="action"
						render={(_, record) => {
							return (
								<Button
									icon={<FontAwesomeIcon icon={faFolderImage} />}
									type="link"
									className="btn-info"
									onClick={() =>
										setToggleModalFormJustificationAttachment({
											open: true,
											data: record.attachments,
										})
									}
									name="btn_preview"
								/>
							);
						}}
					/>

					<Table.Column
						title="Date Created"
						key="created_at_format"
						dataIndex="created_at_format"
						defaultSortOrder={"descend"}
						width={150}
						sorter
					/>
					<Table.Column
						title="Faculty No."
						key="faculty_no"
						dataIndex="faculty_no"
						width={130}
						sorter
					/>
					<Table.Column
						title="Name"
						key="fullname"
						dataIndex="fullname"
						width={200}
						sorter
					/>

					<Table.Column
						title="Status"
						key="status"
						dataIndex="status"
						width={230}
						sorter
					/>

					{location.pathname !== "/faculty-load/present-list" && (
						<Table.Column
							title="Justification"
							key="justification"
							dataIndex="justification"
							align="center"
							width={100}
							render={(text, record) => {
								let data = {
									faculty_load_monitoring_id: record.id,
									...record.faculty_load_monitoring_justification,
								};

								return (
									<>
										{record.faculty_load_monitoring_justification ? (
											<Tooltip
												title={
													record.faculty_load_monitoring_justification
														? record.faculty_load_monitoring_justification
																.code === "S-07"
															? "For Justification"
															: "Justified"
														: "For Justification"
												}
											>
												<FontAwesomeIcon
													icon={faCheckCircle}
													className={
														record.faculty_load_monitoring_justification
															? record.faculty_load_monitoring_justification
																	.code === "S-07"
																? "primary-color"
																: record.faculty_load_monitoring_justification
																		.code === "S-09"
																? "success-color"
																: "danger-color"
															: ""
													}
												/>
											</Tooltip>
										) : null}

										<Tooltip
											placement="top"
											title={
												record.faculty_load_monitoring_justification
													? record.faculty_load_monitoring_justification
															.code === "S-07"
														? "Processing"
														: "Updated & Justified"
													: "Apply for approval"
											}
										>
											<Button
												icon={<FontAwesomeIcon icon={faBalanceScale} />}
												type="link"
												className={
													record.faculty_load_monitoring_justification
														? "btn-disabled"
														: "btn-main-primary"
												}
												onClick={() => {
													if (!record.faculty_load_monitoring_justification) {
														setToggleModalFormIndorseForApproval({
															open: true,
															data: data,
														});
													}
												}}
											/>
										</Tooltip>
									</>
								);
							}}
						/>
					)}

					<Table.Column
						title="Justification Status"
						key="status_update"
						dataIndex="status_update"
						width={200}
						sorter
					/>

					<Table.Column
						title="Justification Remarks"
						key="update_remarks"
						dataIndex="update_remarks"
						width={200}
						sorter
					/>

					<Table.Column
						title="Time"
						key="time"
						dataIndex="time"
						width={180}
						sorter
					/>
					<Table.Column
						title="Room"
						key="room_code"
						dataIndex="room_code"
						width={120}
						sorter
					/>
					<Table.Column
						title="Subject"
						key="code"
						dataIndex="code"
						width={120}
						sorter
					/>
					<Table.Column
						title="School Year"
						key="school_year"
						dataIndex="school_year"
						width={150}
						sorter
					/>
					<Table.Column
						title="Semester"
						key="semester"
						dataIndex="semester"
						width={180}
						sorter
					/>
				</Table>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<Flex
					justify="space-between"
					align="center"
					className="tbl-bottom-filter"
				>
					<div />

					<Flex gap={15} align="center">
						<TableShowingEntriesV2 />
						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							total={dataSource?.data.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper"
						/>
					</Flex>
				</Flex>
			</Col>

			<ModalFormReportUpdateRemarks
				toggleModalFormUpdateRemarks={toggleModalFormUpdateRemarks}
				setToggleModalFormUpdateRemarks={setToggleModalFormUpdateRemarks}
			/>
			<ModalFormFacultyLoadEndorseForApproval
				toggleModalFormIndorseForApproval={toggleModalFormIndorseForApproval}
				setToggleModalFormIndorseForApproval={
					setToggleModalFormIndorseForApproval
				}
			/>
			<ModalFormFacultyLoadJustificationAttachment
				toggleModalFormJustificationAttachment={
					toggleModalFormJustificationAttachment
				}
				setToggleModalFormJustificationAttachment={
					setToggleModalFormJustificationAttachment
				}
			/>
			<ModalFormExcelPrint
				toggleModalExcelPrint={toggleModalExcelPrint}
				setToggleModalExcelPrint={setToggleModalExcelPrint}
				from="Faculty Load Report"
			/>
		</Row>
	);
}
